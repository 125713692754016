export function initialState() {
  return {
    groupList: [],
    groupPatientList: [],
    selectedGroup: undefined,
    isLoading: false,
    step: 1,
    selectedPatients: [],
    formulateDialog: false,
    selectedForm: '',
    populateForm: {},
    currentPatient: {},
    defaultPanel: 0,
    refreshFormulateRationale: 0,
    formsInProgress: true,
    reviewTableKey: 0,
    submissionTableKey: 0,
    selectionTableKey: 0,
    downloadAllDialog: false,
    downloadAllReady: true,
    submitDialog: false,
    resetDialog: false,
    search: '',
  }
}

// Patient
export const patientInfo = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-4 tw-gap-6',
    children: [
      {
        type: 'text',
        label: 'Patient First Name',
        name: 'patientfirstname',
        key: 'patientfirstname',
      },
      {
        type: 'text',
        label: 'Patient Last Name',
        name: 'patientlastname',
        key: 'patientlastname',
      },
      {
        type: 'text',
        label: 'Patient Date of Birth',
        name: 'dob',
        key: 'dob',
      },
    ],
  },
]

// Doc Info
export const doctorInfo = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-3 tw-gap-6',
    children: [
      {
        type: 'text',
        label: 'Doctor First Name',
        name: 'drfirstname',
        key: 'drfirstname',
      },
      {
        type: 'text',
        label: 'Doctor Last Name',
        name: 'drlastname',
        key: 'drlastname',
      },
      {
        type: 'text',
        label: 'CPSO',
        name: 'cpso',
        key: 'cpso',
      },
      {
        type: 'text',
        label: 'Doctor Fax',
        name: 'drfax',
        key: 'drfax',
      },
      {
        type: 'text',
        label: 'Doctor Tel',
        name: 'drtel',
        key: 'drtel',
      },

      // {
      //   type: 'text',
      //   label: 'Doctor Notification Date',
      //   name: 'drNotificationDate',
      //   key: 'drNotificationDate',
      // },
    ],
  },
]

// Pharmacy Info
export const pharmacyInfo = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-4 tw-gap-6',
    children: [
      {
        type: 'text',
        label: 'Pharmacy Name',
        name: 'pharmacyName',
        key: 'pharmacyName',
      },
      {
        type: 'text',
        label: 'Pharmacy Tel Number',
        name: 'pharmacyTelephone',
        key: 'pharmacyTelephone',
      },
      {
        type: 'text',
        label: 'Pharmacy Fax Number',
        name: 'pharmacyFax',
        key: 'pharmacyFax',
      },
      {
        type: 'text',
        label: 'Pharmacy Address',
        name: 'pharmacyFullAdd',
        key: 'pharmacyFullAdd',
      },

    ],
  },
]

// Pharmacist Info
export const pharmacistInfo = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-4 tw-gap-6',
    children: [
      {
        type: 'text',
        label: 'Pharmacist First Name',
        name: 'rphfirstname',
        key: 'rphfirstname',
      },
      {
        type: 'text',
        label: 'Pharmacist Last Name',
        name: 'rphlastname',
        key: 'rphlastname',
      },
      {
        type: 'text',
        label: 'OCP#',
        name: 'ocp',
        key: 'ocp',
      },

      // {
      //   type: 'text',
      //   label: 'Rx Number',
      //   name: 'rxNumber',
      //   key: 'rxNumber',
      // },
    ],
  },
]

// Frequent Dispensing
export const dispRegChoice = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [
      {
        type: 'select',
        label: 'Dispensing Frequency',
        name: 'dispRegChoice',
        key: 'dispRegChoice',
        options: [
          {
            label: 'Every 7 days',
            value: 'Every 7 Days',
          },
          {
            label: 'Every 14 days',
            value: 'Every 14 days',
          },
          {
            label: 'Every 28 days',
            value: 'Every 28 days',
          },
          {
            label: 'Other',
            value: 'Other',
          }],
      },
    ],
  },
]
export const reasoning = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-auto tw-gap-6',
    children: [
      // {
      //   type: 'text',
      //   label: 'Rationale',
      //   name: 'rationaleReasoning',
      //   key: 'rationaleReasoning',
      // },
      {
        type: 'text',
        label: 'Other Reasons',
        name: 'otherReasoning',
        key: 'otherReasoning',
      },

    ],
  },
]
export const rationale = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-auto tw-gap-6',
    children: [
      {
        type: 'select',
        label: 'The rationale/reason(s) for my assessment of the clinical or safety risks to the patient if larger quantities were dispensed, is/are: Based on the above noted impairments, the patient needs a frequently monitored drug regiment due to: ',
        name: 'rationale',
        multiple: 'true',
        key: 'rationale',
        options: [{
          id: 'rationaleOption1',
          label:
              'Medication regimen is complex and may forget to take medications or take multiple times throughout the day resulting in a DRP.',
          value: 'Medication regimen is complex and may forget to take medications or take multiple times throughout the day resulting in a DRP.',
        },
        {
          id: 'rationaleOption2',
          label: 'Frequent changes in medication. Patient unable to manage changes without risk of a DRP. ',
          value: 'Frequent changes in medication. Patient unable to manage changes without risk of a DRP. ',
        },
        {
          id: 'rationaleOption3',
          label: 'Patient at risk for adverse health outcomes. ',
          value: 'Patient at risk for adverse health outcomes. ',
        },
        {
          id: 'rationaleOption4',
          label: 'Patient loses medications. ',
          value: 'Patient loses medications. ',
        },
        {
          id: 'rationaleOption5',
          label: 'Patient has a hard time identifying medications which may result in a DRP. ',
          value: 'Patient has a hard time identifying medications which may result in a DRP. ',
        },
        {
          id: 'rationaleOption6',
          label: 'Patient Finds compliance packaging to be safer and more convenient. ',
          value: 'Patient Finds compliance packaging to be safer and more convenient. ',
        },
        {
          id: 'rationaleOption7',
          label: 'Patient is requesting blister packs to help with compliance. ',
          value: 'Patient is requesting blister packs to help with compliance. ',
        },
        {
          id: 'rationaleOption8',
          label: 'Patient needs close monitoring to ensure they are taking their medications. ',
          value: 'Patient needs close monitoring to ensure they are taking their medications. ',
        },
        {
          id: 'rationaleOption9',
          label: 'High potential for abuse of medications. ',
          value: 'High potential for abuse of medications. ',
        },
        {
          id: 'rationaleOption10',
          label: 'Patient requires assistance with medication regimen and vitamins and compliance. ',
          value: 'Patient requires assistance with medication regimen and vitamins and compliance. ',
        },
        {
          id: 'rationaleOption11',
          label: 'Client of special housing program. ',
          value: 'Client of special housing program. ',
        },
        {
          id: 'rationaleOption12',
          label: 'Unstable housing arrangement. ',
          value: 'Unstable housing arrangement. ',
        }],
      },
    ],
  },
]

export const pharmacistAssessment = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-3 tw-gap-6',
    children: [
      {
        type: 'checkbox',
        label: 'Physical Impairement',
        name: 'physCheck1',
        key: 'physCheck1',
        options: {
          physCheck1: 'Physical functioning',
          physCheck2: 'Mobility',
          physCheck3: 'Dexterity',
          physCheck4: 'Stamina',
          physCheck5: 'Other physical disabilities include impairments which limit other facets of daily living such as: respiratory disorders, blindness, epilepsy, sleep disorders',
        },
      },

      {
        type: 'checkbox',
        label: 'Complex Medication Regimen',
        name: 'regimenCheck1',
        key: 'regimenCheck1',
        options: {
          regimenCheck1: '1-2 = simple regimen (frequent dispensing rarely needed)',
          regimenCheck2: '3-4 = mildly complex',
          regimenCheck3: '5-6 = complex',
          regimenCheck4: '7+ = highly complex',
          regimenCheck5: 'Medications that require cutting',
          regimenCheck6: 'Medications requiring multiple doses per day',
          regimenCheck7: 'Medications on specialized schedules (once a week/everyother day, etc)',
        },
      },
      {
        type: 'checkbox',
        label: 'Sensory impairment',
        name: 'sensoryCheck1',
        key: 'sensoryCheck1',
        options: {
          sensoryCheck1: 'Sight',
          sensoryCheck2: 'Hearing',
          sensoryCheck3: 'Smell',
          sensoryCheck4: 'Touch',
          sensoryCheck5: 'Taste',
          sensoryCheck6: 'Spatial Awareness',
        },
      },
      {
        type: 'checkbox',
        label: 'Cognitive Impairment',
        name: 'cogCheck1',
        key: 'cogCheck1',
        options: {
          cogCheck1: 'Remembering',
          cogCheck2: 'Learning new things',
          cogCheck3: 'Concentrating',
          cogCheck4: 'Making decisions that affect their everyday life ',
          cogCheck5: 'Drug-induced impairment in cognition and memory',
        },
      },
    ],
  },
]

// Blister Pack
export const medChanges = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-3 tw-gap-6',
    children: [
      {
        type: 'text',
        label: 'Dose Change (Increase or Decrease)',
        name: 'dosechange',
        key: 'dosechange',
      },
      {
        type: 'text',
        label: 'List Medications Discontinued:',
        name: 'discontinue',
        key: 'discontinue',
      },
      {
        type: 'text',
        label: 'List New Medications',
        name: 'newMedications',
        key: 'newMedications',
      },
    ],
  },
]

export const patientHeaders = [
  {
    text: 'Last Name',
    class: 'black--text font-weight-bold subtitle-1',
    align: 'left',
    value: 'LastName',
    sortable: false,
  },
  {
    text: 'First Name',
    class: 'black--text font-weight-bold',
    align: 'left',
    value: 'FirstName',
    sortable: false,
  },
  {
    text: 'Patient ID',
    class: 'black--text font-weight-bold',
    align: 'left',
    value: 'PatientID',
    sortable: false,
  },
  {
    text: 'Doctor Selection',
    class: 'black--text font-weight-bold',
    align: 'left',
    value: 'doctors',
    sortable: false,
  },

]

export const reviewHeaders = [
  {
    text: 'Last Name',
    class: 'black--text font-weight-bold subtitle-1',
    align: 'left',
    value: 'LastName',
    sortable: false,
  },
  {
    text: 'First Name',
    class: 'black--text font-weight-bold',
    align: 'left',
    value: 'FirstName',
    sortable: false,
  },
  {
    text: 'Patient ID',
    class: 'black--text font-weight-bold',
    align: 'left',
    value: 'PatientID',
    sortable: false,
  },
  {
    text: 'Doctor Selected',
    class: 'black--text font-weight-bold',
    align: 'left',
    value: 'doctor',
    sortable: false,
  },
  {
    text: 'Status',
    class: 'black--text font-weight-bold',
    align: 'left',
    value: 'status',
    sortable: false,
  },
]

export const submissionHeaders = [
  {
    text: 'Last Name',
    class: 'black--text font-weight-bold subtitle-1',
    align: 'left',
    value: 'LastName',
    sortable: false,
  },
  {
    text: 'First Name',
    class: 'black--text font-weight-bold',
    align: 'left',
    value: 'FirstName',
    sortable: false,
  },
  {
    text: 'Patient ID',
    class: 'black--text font-weight-bold',
    align: 'left',
    value: 'PatientID',
    sortable: false,
  },
  {
    text: 'Doctor Selected',
    class: 'black--text font-weight-bold',
    align: 'left',
    value: 'doctor',
    sortable: false,
  },
  {
    text: 'Submission Status',
    class: 'black--text font-weight-bold',
    align: 'left',
    value: 'submissionStatus',
    sortable: false,
  },
  {
    text: 'Actions',
    class: 'black--text font-weight-bold',
    align: 'left',
    sortable: false,
    value: 'downloadPdf',
  },
]

// export const docHeaders = [
//   {
//     text: 'Last Name',
//     class: 'black--text font-weight-bold subtitle-1',
//     align: 'left',
//     value: 'DoctorLastName',
//     sortable: false,
//   },
//   {
//     text: 'First Name',
//     class: 'black--text font-weight-bold',
//     align: 'left',
//     value: 'DoctorFirstName',
//     sortable: false,
//   },
//   {
//     text: 'CPSO',
//     class: 'black--text font-weight-bold',
//     align: 'left',
//     value: 'CPSO',
//     sortable: false,
//   },
//   {
//     text: 'Address',
//     class: 'black--text font-weight-bold',
//     align: 'left',
//     value: 'DoctorAddress',
//     sortable: false,
//   },
//   {
//     text: 'Phone',
//     class: 'black--text font-weight-bold',
//     align: 'left',
//     value: 'DoctorPhone',
//     sortable: false,
//   },
//   {
//     text: 'Fax',
//     class: 'black--text font-weight-bold',
//     align: 'left',
//     value: 'DoctorFax',
//     sortable: false,
//   },
//   {
//     text: 'Prescriptions in Last Year',
//     class: 'black--text font-weight-bold',
//     align: 'left',
//     value: 'RXperProvider',
//     sortable: false,
//   },
// ];

export const blisterpackHeaders = [
  {
    text: 'Brand Name',
    align: 'start',
    value: 'BrandName',
  },
  {
    text: 'Strength',
    align: 'left',
    value: 'Strength',
  },
  {
    text: 'SIG',
    align: 'left',
    value: 'SIGFull',
  },
  {
    text: 'Brk',
    align: 'left',
    value: 'Brk',
  },
  {
    text: 'Noon',
    align: 'left',
    value: 'Noon',
  },
  {
    text: 'Supp',
    align: 'left',
    value: 'Supp',
  },
  {
    text: 'Bed',
    align: 'left',
    value: 'Bed',
  },
  {
    text: 'description',
    align: 'left',
    value: 'description',
  },
]

export const formsList = [
  {
    character: require('@/assets/images/icons/Blister Pack.png'),
    category: 'demand-generation',
    characterSize: '80',
    title: 'Blister Pack Authorization Request',
    desc: '',
    formType: 'blisterPackGrid',
    page: 'blisterpackgroup',

    // Status: 'Coming Soon',
  },
  {
    character: require('@/assets/images/icons/Medical History.png'),
    category: 'sales-automation',
    characterSize: '80',
    title: 'Frequent Dispensing Assessment',
    desc: '',
    formType: 'frequentDispensingAssessment',
    page: 'patient-form-frequentassessment',

    // Status: 'Coming Soon',
  },

  {
    character: require('@/assets/images/icons/Medical Services.png'),
    category: 'sales-automation',
    characterSize: '80',
    title: 'Frequent Dispensing Re-Assessment',
    desc: '',
    formType: 'frequentReassessment',
    page: 'patient-form-frequentreassessment',

    // Status: 'Coming Soon',
  },

  // {
  //   character: require('@/assets/images/pharmacyIcons/IHSS Agency.png'),
  //   category: 'Group Creator',
  //   characterSize: '80',
  //   title: 'Group Creator',
  //   desc: '',

  // page: 'compliance-group-view',
  //   Status: 'Coming Soon',
  // },
  // {
  //   character: require('@/assets/images/logos/greenshield.png'),
  //   category: 'demand-generation',
  //   characterSize: '80',
  //   title: 'Green Shield Request',
  //   desc: '',

  // page: 'compliance-green-shield',
  // Status: 'Coming Soon',
  // },
  // {
  //   character: require('@/assets/images/logos/assure.png'),
  //   category: 'demand-generation',
  //   characterSize: '80',
  //   title: 'Assure Request',
  //   desc: '',

  // page: 'compliance-assure',
  // Status: 'Coming Soon',
  // },
  // {
  //   character: require('@/assets/images/pharmacyIcons/Schedule.png'),
  //   category: 'demand-generation',
  //   characterSize: '80',
  //   title: 'Group Home Tracker',
  //   desc: '',

  // page: 'compliance-group-view',
  // Status: 'Coming Soon',
  // },
]
