<template>
  <section id="group-form-base">
    <v-card
      flat
      class="group-form-base-bg d-flex align-center justify-center text-center mb-7"
    >
      <v-card-text id="patientViewFile">
        <div class="mx-auto tw-w-1/2">
          <v-autocomplete
            id="input-group-autocomplete"
            v-model="selectedGroup"
            label="Select Group from List"
            solo
            clearable
            :items="groupList"
            filled
            return-object
            @change="loadPatientsFromGroup()"
          ></v-autocomplete>
        </div>

        <v-form
          v-if="selectedGroup && groupPatientList.length"
          id="form-group-form-base"
          class="kb-search-input mx-auto"
        >
          <v-text-field
            id="form-group-form-base-search"
            v-model="knowledgeBaseSearchQuery"
            outlined
            placeholder="Search for form..."
            hide-details
            class="kb-search-input"
          >
            <template #prepend-inner>
              <v-icon
                size="23"
                class="mx-1"
              >
                {{ icons.mdiMagnify }}
              </v-icon>
            </template>
          </v-text-field>
        </v-form>
      </v-card-text>
    </v-card>
    <v-stepper
      v-if="selectedGroup !== undefined"
      v-model="step"
    >
      <v-stepper-header>
        <v-stepper-step
          :complete="step > 1"
          step="1"
        >
          Form Selection
          <small class="tw-mt-3 tw-font-black">Select the form for the group</small>
        </v-stepper-step>

        <v-divider></v-divider>
        <v-stepper-step
          :complete="step > 2"
          step="2"
        >
          Patient Selection
          <small class="tw-mt-3 tw-font-black">Select patient(s) and a doctor for the patient(s)</small>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="step > 3"
          step="3"
        >
          Review Table
          <small class="tw-mt-3 tw-font-black">Complete forms for patients and sign off</small>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="4">
          Submissions
          <small class="tw-mt-3 tw-font-black">Download your completed forms</small>
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card
            id="group-form-base-content"
            class="tw-mt-10"
          >
            <!-- Form Selection Data Table  -->
            <!-- kb search content -->
            <v-card-title class="tw-mb-5 tw-justify-center">
              Select a Form
            </v-card-title>
            <v-card-text>
              <v-row class="kb-search-content-info match-height">
                <v-col
                  v-for="(item,index) in filteredKB"
                  :key="item.character"
                  md="4"
                  sm="6"
                  cols="12"
                  class="kb-search-content"
                >
                  <v-card
                    :id="`group-form-base-content-form-card${index}`"
                    color="text-center cursor-pointer"
                    @click="formSelection(item.formType)"
                  >
                    <div class="tw-flex tw-justify-start">
                      <div
                        v-if="item.Status"
                        class="tw-badge tw-badge-info"
                      >
                        {{ item.Status }}
                      </div>
                    </div>
                    <div class="kb-character-wrapper">
                      <v-img
                        contain
                        :max-width="item.characterSize"
                        class="mx-auto align-center justify-center"
                        :src="item.character"
                      ></v-img>
                    </div>

                    <!-- title -->
                    <v-card-title class="justify-center pb-3 tw-break-normal">
                      {{ item.title }}
                    </v-card-title>
                    <v-card-text>{{ item.desc }}</v-card-text>
                  </v-card>
                </v-col>
                <!-- no result found -->
                <v-col
                  v-show="!filteredKB.length"
                  cols="12"
                  class="text-center"
                >
                  <h4 class="mt-4">
                    Search result not found!!
                  </h4>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card
            v-if="selectedGroup"
            class="mb-12"
          >
            <!-- Patient Selection Data Table  -->
            <v-card-title class="tw-justify-center">
              Select Patient/Patients
            </v-card-title>
            <v-card-text>
              <v-data-table
                id="form-group-form-base-patient-table"
                :key="selectionTableKey"
                v-model="selectedPatients"
                :headers="patientHeaders"
                :items="groupPatientList"
                item-key="PatientID"
                show-select
                @click:row="patientRowSelection"
              >
                <template v-slot:item.doctors="{ item }">
                  <v-select
                    v-model="item.doctor"
                    :items="item.doctors"
                    return-object
                    item-key="DoctorID"
                    item-text="DoctorLastName"
                  >
                  </v-select>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                id="group-form-base-content-button-previous"
                color="primary"
                large
                @click="step = 1"
              >
                Previous
              </v-btn>
              <v-btn
                id="form-group-form-base-patient-table-btn-continue"
                color="primary"
                large
                :disabled="!selectedPatients.length"
                @click="confirmPatients"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card
            id="group-form-base-content"
            class="tw-mt-10"
          >
            <v-card-title class="tw-mb-5 tw-justify-center">
              Review Table
            </v-card-title>
            <v-card-text>
              <!-- FIXME: Delete when ready to merge to dev, this is for testing only when submitting many patients -->
              <v-btn @click="testSubmitAll">
                TESTING BUTTON ONLY TO SUBMIT ALL
              </v-btn>
              <v-data-table
                id="form-group-form-base-review-table"
                :key="reviewTableKey"
                :headers="reviewHeaders"
                :items="selectedPatients"
                item-key="PatientID"
                @click:row="patientSelect"
              >
                <!-- ROW CLICK TO OPEN FORM -->
                <template v-slot:item.doctor="{ item }">
                  <span v-if="item.doctor">
                    {{ item.doctor.DoctorFirstName }} {{ item.doctor.DoctorLastName }}
                  </span>
                </template>
                <template v-slot:item.status="{ item }">
                  <v-icon
                    v-if="item.status === 'Complete'"
                    size="20"
                    color="success"
                  >
                    {{ mdiCheckCircleOutline }}
                  </v-icon>
                  <v-icon
                    v-if="item.status !== 'Complete'"
                    size="20"
                    color="warning"
                  >
                    {{ mdiAlertCircleOutline }}
                  </v-icon>
                </template>
              </v-data-table>
              <!-- Signature Here -->
              <v-divider></v-divider>
              <div
                v-if="!formsInProgress && selectedForm !== 'blisterPackGrid'"
                class="tw-mt-5"
              >
                <h3 class="tw-text-center tw-text-xl tw-font-semibold">
                  Sign a pharmacist for all forms
                </h3>
                <pg-sig-input
                  @save="saveSignature"
                  @updatePharmacist="updatePharmacist"
                ></pg-sig-input>
              </div>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                id="form-group-form-base-review-table-btn-previous"
                color="primary"
                large
                @click="step = 2"
              >
                Previous
              </v-btn>

              <v-btn
                id="form-group-form-base-review-table-btn-continue"
                color="primary"
                large
                :disabled="formsInProgress"
                @click="submitDialog = true"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <!-- Final Table -->
        <v-stepper-content step="4">
          <v-card
            id="group-form-base-content"
            class="tw-mt-10"
          >
            <v-card-title class="tw-mb-5 tw-justify-center">
              Submission List
            </v-card-title>
            <v-card-text>
              <div class="tw-flex tw-justify-end tw-my-5">
                <v-btn
                  class="tw-mr-2"
                  color="primary"
                  :disabled="downloadAllReady"
                  @click="downloadPdfAll"
                >
                  Download All
                </v-btn>
              </div>
              <v-data-table
                id="form-group-form-base-review-table"
                :key="submissionTableKey"
                :headers="submissionHeaders"
                :items="selectedPatients"
                item-key="PatientID"
              >
                <template v-slot:item.doctor="{ item }">
                  <span v-if="item.doctor">
                    {{ item.doctor.DoctorFirstName }} {{ item.doctor.DoctorLastName }}
                  </span>
                </template>
                <template v-slot:item.submissionStatus="{ item }">
                  <v-icon
                    v-if="item.submissionStatus === 'Complete'"
                    size="20"
                    color="success"
                  >
                    {{ mdiCheckCircleOutline }}
                  </v-icon>
                  <v-progress-circular
                    v-if="item.submissionStatus !== 'Complete'"
                    indeterminate
                    color="primary"
                    size="15"
                    width="2"
                  ></v-progress-circular>
                </template>

                <template v-slot:item.downloadPdf="{ item, index }">
                  <v-icon
                    v-if="item.submissionStatus === 'Complete'"
                    color="primary"
                    medium
                    class="me-3"
                    @click="downloadPdf(item.pdfRef)"
                  >
                    {{ mdiCloudDownload }}
                  </v-icon>
                </template>
              </v-data-table>
              <div class="tw-flex tw-justify-end tw-mt-5">
                <v-btn
                  color="primary"
                  :disabled="downloadAllReady"
                  @click="resetDialog = true"
                >
                  Restart Process
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <!-- Form -->
    <v-dialog
      id="form-group-form-base-form-dialog"
      v-model="formulateDialog"
      width="1200px"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="tw-mb-5 justify-center">
          {{ convertCamelCase(selectedForm) }} - {{ currentPatient.FirstName }} {{ currentPatient.LastName }}
        </v-card-title>
        <v-card-text>
          <v-expansion-panels
            v-model="defaultPanel"
            active-class
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                Data Review
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- Form Inputs -->
                <div v-if="selectedForm === 'frequentDispensingAssessment' || selectedForm === 'frequentReassessment'">
                  <!-- <p class="tw-w-1/2 tw-font-black"> -->
                  <p class="tw-font-black">
                    It is my professional opinion that the patient above requires a more frequent medication dispensing interval to help him/her achieve desired health outcomes, as he/she is incapable of managing his/her medication regimen as a result of a:
                  </p>
                  <FormulateForm
                    v-model="currentPatient.pharmacistAssessmentValue"
                    :schema="pharmacistAssessment"
                    name="pharmacistAssessment"
                  >
                  </FormulateForm>
                  <FormulateForm
                    :key="refreshFormulateRationale"
                    v-model="currentPatient"
                    name="rationale"
                    :schema="rationale"
                    :keep-model-data="true"
                  >
                  </FormulateForm>
                  <FormulateForm
                    v-model="currentPatient"
                    :schema="reasoning"
                    name="reasoning"
                  >
                  </FormulateForm>
                  <FormulateForm
                    v-model="currentPatient"
                    :schema="dispRegChoice"
                    name="dispRegChoice"
                  >
                  </FormulateForm>
                </div>

                <div v-if="selectedForm === 'blisterPackGrid'">
                  <FormulateForm
                    v-model="currentPatient"
                    :schema="medChanges"
                  >
                  </FormulateForm>

                  <v-container fluid>
                    <v-data-table
                      :headers="blisterpackHeaders"
                      :items="currentPatient.blisterMedsList"
                      :search="search"
                      :items-per-page="15"
                      class="table-kitchen-sink"
                      item-key="RxNumber"
                    >
                    </v-data-table>
                  </v-container>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Patient Info
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <FormulateForm
                  v-model="currentPatient"
                  :schema="patientInfo"
                >
                </FormulateForm>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <!-- <v-expansion-panel-header>
                Doctor Info, Pharmacist Info &amp Pharmacy Info
              </v-expansion-panel-header> -->
              <v-expansion-panel-header>
                <template v-slot:actions>
                  <v-icon
                    v-if="typeof(currentPatient.drlastname) === 'undefined'"
                    color="warning"
                  >
                    {{ mdiAlertCircleOutline }}
                  </v-icon>
                </template>
                Doctor Info
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="tw-my-2">
                  <h1 class="tw-font-weight-bold title mb-3">
                    Doctor Info
                  </h1>
                  <FormulateForm
                    v-model="currentPatient"
                    :schema="doctorInfo"
                  >
                  </FormulateForm>
                </div>
                <!-- <div class="tw-my-2">
                  <h1 class="tw-font-weight-bold title mb-3">Pharmacist Info</h1>
                  <FormulateForm
                    :schema="pharmacistInfo"
                    v-model="currentPatient"
                  >
                  </FormulateForm>
                </div> -->
                <!-- <div class="tw-mt-5">
                  <h1 class="tw-font-weight-bold title mb-3">Pharmacy Info</h1>
                  <FormulateForm
                    :schema="pharmacyInfo"
                    v-model="currentPatient"
                  >
                  </FormulateForm>
                </div> -->
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="error"
            @click="closeFormulateDialog"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            @click="submitPatient"
          >
            Submit Patient
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="submitDialog"
      width="600"
    >
      <v-card>
        <v-card-title class="tw-my-3 tw-justify-center">
          Continuing will submit forms for all completed patients.
        </v-card-title>
        <v-card-actions class="tw-justify-center">
          <v-btn
            color="error"
            @click="submitDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            @click="submitForms"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      id="form-group-form-base-form-dialog-print"
      v-model="downloadAllDialog"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="headline text-xs-center">
          Downloading all forms...
        </v-card-title>
        <v-card-text class="text-xs-center">
          <v-progress-linear indeterminate></v-progress-linear>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isLoading"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="pt-3">
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="resetDialog"
      persistent
      width="600"
    >
      <v-card>
        <v-card-title class="tw-my-3 tw-justify-center">
          Confirm to reset and process another group.
        </v-card-title>
        <v-card-actions class="tw-justify-center">
          <v-btn
            color="error"
            @click="resetDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            @click="resetProcess"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
/* eslint-disable implicit-arrow-linebreak */
import {
  mdiMagnify,
  mdiAccountHeart,
  mdiPill,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
  mdiCloudDownload,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { mapGetters, mapState } from 'vuex'
import {
  patientInfo,
  doctorInfo,
  pharmacistInfo,
  pharmacyInfo,
  dispRegChoice,
  reasoning,
  rationale,
  pharmacistAssessment,
  medChanges,
  patientHeaders,
  reviewHeaders,
  submissionHeaders,
  blisterpackHeaders,
  formsList,
  initialState,
} from '@/assets/formSchema/groupSchema'
import pgSigInput from '@/components/common/ui/Form/pgSigInput/pgSigInput.vue'
import { stateService } from '@/render/api/zarya'

export default {
  name: 'GroupFormsHome',
  components: {
    'pg-sig-input': pgSigInput,
  },
  data() {
    return {
      initialState,
      user: this.$auth.user,
      mdiCheckCircleOutline,
      mdiAlertCircleOutline,
      mdiCloudDownload,
      patientHeaders,
      reviewHeaders,
      blisterpackHeaders,
      submissionHeaders,

      // Formulate Data

      patientInfo,
      doctorInfo,
      pharmacistInfo,
      pharmacyInfo,

      // freq dispense
      dispRegChoice,
      reasoning,
      rationale,
      pharmacistAssessment,

      // blister pack
      medChanges,

      // GroupListSelection
      groupList: [],
      groupPatientList: [],
      selectedGroup: undefined,
      isLoading: false,

      step: 1,
      selectedPatients: [],

      formulateDialog: false,
      selectedForm: '',
      populateForm: {},
      currentPatient: {},
      defaultPanel: 0,
      refreshFormulateRationale: 0,

      // form submission
      formsInProgress: true,
      reviewTableKey: 0,
      submissionTableKey: 0,
      selectionTableKey: 0,

      downloadAllDialog: false,
      downloadAllReady: true,
      submitDialog: false,
      resetDialog: false,

      // table search & headers
      search: '',
    }
  },
  setup() {
    const knowledgeBaseSearchQuery = ref('')
    const patientFormsData = formsList

    const filteredKB = computed(() => {
      const knowledgeBaseSearchQueryLower = knowledgeBaseSearchQuery.value.toLowerCase()

      return patientFormsData.filter(
        item =>
          // eslint-disable-next-line operator-linebreak
          item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower) ||
          item.desc.toLowerCase().includes(knowledgeBaseSearchQueryLower),
      )
    })

    return {
      knowledgeBaseSearchQuery,
      patientFormsData,
      filteredKB,
      icons: { mdiMagnify, mdiAccountHeart, mdiPill },
    }
  },
  computed: {
    ...mapGetters(['UserProfile', 'PharmacyProfile', 'PatientProfile', 'FormClass']),
    ...mapState(['User'], ['PatientProfileStore']),
  },
  watch: {
    // Use watcher for now to track the download progress to enable download all button
    submissionTableKey() {
      if (this.submissionTableKey >= this.selectedPatients.length) {
        this.downloadAllReady = false
      }
    },
  },
  async mounted() {
    this.groupList = await this.getGroupList()
  },
  methods: {
    patientRowSelection(patient) {
      const matchedIndex = this.selectedPatients.findIndex(
        selectedPatient => selectedPatient.PatientID === patient.PatientID,
      )
      if (matchedIndex > -1) {
        return this.selectedPatients.splice(matchedIndex, 1)
      }
      this.selectedPatients.push(patient)
    },
    testSubmitAll() {
      this.selectedPatients.forEach(patient => (patient.status = 'Complete'))
      this.formsInProgress = false
      this.selectedPatients.forEach(patient => (patient.submissionStatus = 'Processing'))
      this.reviewTableKey++
    },
    async resetProcess() {
      Object.assign(this.$data, this.initialState())
      this.groupList = await this.getGroupList()
    },
    saveSignature({ pharmacistSig, signatureDate }) {
      this.selectedPatients.forEach(patient => {
        patient.PharmacistSignature = pharmacistSig
        patient.rphsignature = pharmacistSig
        patient.DATE = signatureDate
      })
    },
    updatePharmacist({ pharmacistName, ocp }) {
      this.selectedPatients.forEach(patient => {
        patient.rphfirstname = pharmacistName
        patient.ocp = ocp
        patient.rphName = pharmacistName
        patient.rphfirstname = pharmacistName.split(' ')[0]
        patient.rphlastname = pharmacistName.split(' ')[1]
      })
    },
    formulateMapping() {
      const checkValue = 'Yes'
      const results = Object.values(this.currentPatient.pharmacistAssessmentValue)
        .flat()
        .reduce(
          (acc, propertyName) => ({
            ...acc,
            [propertyName]: checkValue,
          }),
          {},
        )
      this.currentPatient = {
        ...this.currentPatient,
        ...results,
      }
      if (this.currentPatient.rationale) {
        this.currentPatient = {
          ...this.currentPatient,
          rationaleReasoning: this.currentPatient.rationale.toString(),
        }
      }
    },
    formSelection(form) {
      this.selectedForm = form
      this.preselectDoctor()
      this.$store.dispatch('setFormClass', this.selectedForm)
      this.step = 2
      this.selectionTableKey++
    },
    confirmPatients() {
      if (this.selectedForm === 'blisterPackGrid') {
        this.searchBlisterMeds()
      }
      this.addDoctor()
      this.selectedPatients.forEach(patient => (patient.status = 'Incomplete'))
      this.reviewTableKey++
      this.step = 3
    },
    closeFormulateDialog() {
      // To save progress of the patient being worked on
      const matchedIndex = this.selectedPatients.findIndex(
        patient => patient.PatientID === this.currentPatient.PatientID,
      )
      this.selectedPatients[matchedIndex] = this.currentPatient
      this.currentPatient = {
        rationale: [],
      }
      this.formulateDialog = false
    },
    formatData() {
      this.groupPatientList = this.groupPatientList.map(patient => ({
        ...patient,
        patientfirstname: patient.FirstName,
        patientlastname: patient.LastName,
        dob: patient.Birthday,
        rphfirstname: this.$store.state.User.user.firstName,
        rphlastname: this.$store.state.User.user.lastName,
        ocp: this.$store.state.User.user.ocp,
        pharmacyName: this.$store.state.User.pharmacy.name,
        pharmacyFax: this.$store.state.User.pharmacy.fax,
        pharmacyTelephone: this.$store.state.User.pharmacy.phone,
        pharmacyFullAdd: `${this.$store.state.User.pharmacy.address.streetNumber} ${this.$store.state.User.pharmacy.address.streetName}, ${this.$store.state.User.pharmacy.address.city} ${this.$store.state.User.pharmacy.address.state} ${this.$store.state.User.pharmacy.address.code}`,
      }))
    },
    patientSelect(patient) {
      this.formulateDialog = true
      this.defaultPanel = 0
      if (this.selectedForm === 'frequentDispensingAssessment' || this.selectedForm === 'frequentReassessment') {
        if (typeof patient.rationale === 'undefined') {
          this.selectedPatients.map(patient => (patient.rationale = []))
        }
      }
      const match = this.selectedPatients.findIndex(form => form.PatientID === patient.PatientID)
      if (match !== -1) {
        this.currentPatient = this.selectedPatients[match]

        return
      }
      this.currentPatient = patient
    },
    submitPatient() {
      if (typeof this.currentPatient.pharmacistAssessmentValue !== 'undefined') {
        this.formulateMapping()
      }
      if (this.currentPatient.drfirstname) {
        this.currentPatient.doctor = {
          // "DoctorID":298,
          // "PatientID":618,
          DoctorFirstName: this.currentPatient.drfirstname,
          DoctorLastName: this.currentPatient.drlastname,
          CPSO: this.currentPatient.cpso,

          // "DoctorEmail":"",
          DoctorPhone: this.currentPatient.drtel,
          DoctorFax: this.currentPatient.fax,

          // "DoctorAddress":"960 LAWRENCE AVE W",
          // "DoctorCity":"NORTH YORK",
          // "DoctorProvince":"ON",
          // "DoctorPostal":"M6A 3B5",
          // "DoctorSelectId":"960 LAWRENCE AVE W41625677314167814515",
          // "RXperProvider":127,
          selected: true,
        }
      }
      const match = this.selectedPatients.findIndex(patient => patient.PatientID === this.currentPatient.PatientID)
      if (match !== -1) {
        this.selectedPatients[match] = this.currentPatient
      }

      this.selectedPatients[match].status = 'Complete'
      this.reviewTableKey++
      this.formulateDialog = false

      if (this.selectedPatients.every(patient => patient.status === 'Complete')) {
        this.formsInProgress = false
        this.selectedPatients.forEach(patient => (patient.submissionStatus = 'Processing'))
      }
    },
    addDoctor() {
      this.selectedPatients.forEach(patient => {
        if (typeof patient.doctor !== 'undefined') {
          patient.drfirstname = patient.doctor.DoctorFirstName
          patient.drlastname = patient.doctor.DoctorLastName
          patient.cpso = patient.doctor.CPSO
          patient.drtel = patient.doctor.DoctorPhone
          patient.drfax = patient.doctor.DoctorFax
        }
      })
    },
    preselectDoctor() {
      this.groupPatientList.forEach(patient => {
        if (patient.doctors.length) {
          patient.doctor = patient.doctors[0]
        }
      })
    },
    submitForms() {
      this.submitDialog = false
      this.step = 4
      this.selectedPatients.forEach(patient => {
        this.$store
          .dispatch('pdfSubmitData', {
            filename: `${this.selectedForm}-${patient.LastName}`,
            formType: this.selectedForm,
            pdfData: patient,
          })
          .then(
            pdfRef => {
              patient.submissionStatus = 'Complete'
              patient.pdfRef = pdfRef
              this.saveDateSubmitted(patient)
              this.savePdfInfo(patient)
              this.submissionTableKey++
            },
            err => {
              console.log(err)
            },
          )
      })
    },
    savePdfInfo(patient) {
      this.updateStateStatus(patient)
    },
    async updateStateStatus(patient) {
      let res
      const data = {
        formType: this.selectedForm,
        state: patient,
        patientID: patient.PatientID,
        userID: this.UserProfile._id,
        pharmacyID: this.PharmacyProfile._id,
      }
      const token = await this.$auth.getTokenSilently()
      try {
        if (!patient.stateId) {
          res = await stateService.createState(token, {
            ...data,
          })
          patient.stateId = res.state._id
        }
        res = await stateService.updateState(token, patient.stateId, {
          ...data,
          status: 'complete',
        })

        return res
      } catch (err) {
        console.log(err)
      }

      return res
    },
    saveDateSubmitted(patient) {
      const current = new Date()
      const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`
      const time = `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`
      const dateTime = `${date} ${time}`
      patient.dateSubmitted = dateTime
    },
    async downloadPdf(pdfRef) {
      const token = await this.$auth.getTokenSilently()
      await this.$store.dispatch('pdfDownload', {
        token,
        id: pdfRef.id,
        filename: `${pdfRef.filename.toLowerCase()}`,
      })
    },
    downloadPdfAll() {
      this.downloadAllDialog = true
      this.selectedPatients.forEach(({ pdfRef }) => {
        this.downloadPdf(pdfRef)
      })
      this.downloadAllDialog = false
    },
    searchBlisterMeds() {
      this.selectedPatients.forEach(async patient => {
        patient.blisterMedsList =
          (await this.$root.$system.DBAdapterConn.getBlisterPackMedications(patient.PatientID)) || []
        patient.blisterMedsList.forEach((medication, index) => {
          patient[`med${index + 1}`] = patient.blisterMedsList[index].GenericName
          patient[`medb${index + 1}`] = patient.blisterMedsList[index].BrandName
          patient[`str${index + 1}`] = patient.blisterMedsList[index].Strength
          patient[`sig${index + 1}`] = patient.blisterMedsList[index].SIGFull
          patient[`Brk${index + 1}`] = patient.blisterMedsList[index].Brk
          patient[`Noon${index + 1}`] = patient.blisterMedsList[index].Noon
          patient[`Supp${index + 1}`] = patient.blisterMedsList[index].Supp
          patient[`Bed${index + 1}`] = patient.blisterMedsList[index].Bed
        })
      })
    },
    async loadPatientsFromGroup() {
      if (this.selectedGroup === null) {
        this.isLoading = false
      } else {
        try {
          this.isLoading = true
          let patientList = await this.$root.$system.DBAdapterConn.getGroupPatientList(this.selectedGroup.value)

          const patientIDList = patientList.map(patient => patient.PatientID)
          const doctorsList = await this.$root.$system.DBAdapterConn.getSearchByDoctor(patientIDList)

          patientList = patientList.map(patient => {
            const patientDoctorsList = doctorsList
              .filter(doctor => doctor.PatientID === patient.PatientID)
              .map(doctor => ({ ...doctor, selected: false }))

            if (patientDoctorsList[0]) {
              patientDoctorsList[0].selected = true
            }

            return {
              ...patient,
              doctors: patientDoctorsList || [],
            }
          })

          this.$store.dispatch('PatientGroup/setPatientsList', patientList)

          // this.$store.dispatch('PatientGroup/setGroup', patientList)
          this.$store.dispatch('PatientGroup/setGroup', this.selectedGroup)

          this.groupPatientList = patientList
          if (this.selectedPatients.length) this.selectedPatients = []
          this.isLoading = false
        } catch (e) {
          throw new Error(e)
        }
      }
      this.formatData()
      this.step = 1
    },
    async getGroupList() {
      try {
        return (await this.$root.$system.DBAdapterConn.getGroupList()).map(row => ({
          text: row.Name,
          value: row.GroupID,
        }))
      } catch (e) {
        throw new Error(e)
      }
    },
    convertCamelCase(str) {
      return str.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())
    },

    // capitalizeFirstLetter(str) {
    //   str = str.toLowerCase()

    //   return str.charAt(0).toUpperCase() + str.slice(1)
    // },
    // changeNameCase() {
    //   if (this.PatientProfile['FIRST NAME'] != null) {
    //     this.patientFullName = `${this.capitalizeFirstLetter(
    //       this.PatientProfile['FIRST NAME'],
    //     )} ${this.capitalizeFirstLetter(this.PatientProfile['LAST NAME'])}`
    //   }
    // },
    // preloadForm() {
    //   this.patientData = {
    //     ...this.$store.state.Patient.data,
    //   }
    //   this.medicationsList = this.$store.state.Patient.medications
    //   this.patientProfile = {
    //     ...this.$store.state.Patient.data,
    //   }
    // },
    // async getUserInfo() {
    //   const id = this.$auth.user.sub.replace('auth0|', '')
    //   const token = await this.$auth.getTokenSilently()
    //   this.$store.dispatch('getUser', token).then(profile => {
    //     const $system = new SystemConnection(profile.pharmacy.serverConfig, profile.pharmacy.messageQueueConfig)

    //     return $system.init().then(async () => {
    //       this.$root.$system = $system
    //       window.$system = $system
    //     })
    //   })
    //   this.userPharmacies = await userService
    //     .getUserPharmacies(
    //       {
    //         id,
    //       },
    //       token,
    //     )
    //     .then(response => response)

    //   this.accNumbers = await this.userPharmacies.map(pharmacy => ({
    //     text: pharmacy.name,
    //     value: pharmacy._id,
    //     accNumber: pharmacy.accNumber,
    //   }))
    // },
    // forceRerender() {
    //   this.$store.dispatch('resetPatientData')
    //   this.$store.dispatch('resetState')
    //   this.activePharmacyKey += 1
    //   this.getUserInfo()

    // this.updateIntercom()
    // this.sentryUser()
    // },
  },
}
</script>

<style lang="scss">
@import '@/styles/groupFormsHome.scss';
.v-dialog--active {
  scroll-behavior: smooth;
}
#patientViewFile {
  span.font-weight-black.text-no-wrap {
    font-weight: 900 !important;
    font-size: 24px !important;
  }
  span.pharmacyName {
    font-weight: 900 !important;
    font-size: 16px !important;
  }
}
#groupselecter {
  max-width: 28.125rem;
  border-radius: 5px;
  .theme--light.v-text-field > .v-input__control > .v-input__slot {
    background: #fff !important;
  }
}
input[type='checkbox'] {
  transform: scale(1.5);
}
</style>
